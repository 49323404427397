let url = ''

if(process.env.NODE_ENV === 'development') {
    url = '/api'
} else if(process.env.NODE_ENV === 'production') {
    // url = 'https://customer.lianbianhz.top/customer/'
    //新域名
    url = 'https://customer.jiangjianghz.top/customer/'
    // url = 'https://47.111.92.176/customer/'
}
export default url