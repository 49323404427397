import axios from 'axios'
import {  message  } from 'ant-design-vue';
import { getToken } from '@/utils/TokenCache'
import baseurl from './baseUrl'

// create an axios instance
const service = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  baseURL: baseurl, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 105000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    // console.log("tokenDes="+getToken());
    config.headers['tokenDes'] = getToken() || '';
    return config
  },
  error => {
    // do something with request error
    // console.log(error) // for debug
    message.error(error);
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    if(res.isSuccess){
      return res
    }else{
      // console.log(res)
      message.error(res.msg);

    }

  },
  error => {
    // console.log('err' + error) // for debug
    message.error(error);
    return Promise.reject(error)
  }
)

export default service
